import React, { useState, useEffect } from "react";

/// React router dom
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import "../css/style.css";
/// Css
import "./index.css";
import "./chart.css";

// Cards

import CardsPrimero from "./components/cards/CardsPrimero";
import Cards2Primero from "./components/cards_2/Cards2Primero";
import Cards3Primero from "./components/cards_3/Cards3Primero";
import Cards4Primero from "./components/cards_4/Cards4Primero";

//Game
import Game1 from "./components/games/Game1.js";

// Accesories
import Accesories from "./components/accesories/AccesoriesPrimero";

// Sounds-basket
import HuevosComponent from "./components/sounds-basket/HuevosComponent";

// Resources
import Resources from "./components/resources/Resources";

/// Layout
import Nav from "./layouts/nav";

/// Deshboard
import Home from "./components/Dashboard/Home";

//Firebase
import { firebaseApp } from "../login/FireBase";
import firebase from "firebase/app";
import "firebase/firestore";
import AccesoriesPrimero from "./components/accesories/AccesoriesPrimero";
const db = firebaseApp.firestore(firebaseApp);

const Markup = () => {
  const router = useHistory();

  const routes = [
    /// Deshborad
  ];

  const [datosUsuario, setDatosUsuario] = useState({
    accesories: [],
    cartas: {
      level1: [],
      level2: [],
      level3: [],
      level4: [],
      level5: [],
      level6: [],
    },
  });

  const [usuarioFirebase, setUsuarioFirebase] = useState("usuario");

  useEffect(() => {
    (async () => {
      await firebase.auth().onAuthStateChanged((usuario) => {
        if (!usuario) {
          router.push("/");
        } else {
          db.collection("usuarios")
            .where("usuarioId", "==", usuario.uid)
            .get()
            .then(function (resultado) {
              resultado.forEach(function (doc) {
                setDatosUsuario(doc.data());
                if (doc.data().usuarioActivo === false) {
                  router.push("/");
                }
              });
            })
            .then(() => {
              setUsuarioFirebase(usuario.displayName);
              console.log(datosUsuario);
            });
        }
      });
    })();
  }, []);

  return (
    <Router basename="/dashboard">
      <div id="main-wrapper" className="show">
        <Nav datosArray={datosUsuario.nombre} />

        <div className="content-body">
          <div className="container-fluid">
            <Switch>
              <Route
                exact
                path="/"
                render={(props) => (
                  <Home {...props} datosArray={datosUsuario} />
                )}
              />
              <Route
                exact
                path="/level-1"
                render={(props) => (
                  <CardsPrimero
                    {...props}
                    datosArray={datosUsuario.cartas.level1}
                  />
                )}
              />
              <Route
                exact
                path="/level-2"
                render={(props) => (
                  <Cards2Primero
                    {...props}
                    datosArray={datosUsuario.cartas.level2}
                  />
                )}
              />
              <Route
                exact
                path="/level-3"
                render={(props) => (
                  <Cards3Primero
                    {...props}
                    datosArray={datosUsuario.cartas.level3}
                  />
                )}
              />
              <Route
                exact
                path="/level-4"
                render={(props) => (
                  <Cards4Primero
                    {...props}
                    datosArray={datosUsuario.cartas.level4}
                  />
                )}
              />

              {/* <Route exact path="/game-level-1" render={(props) => <Game1 />} />
              <Route
                exact
                path="/accesories"
                render={(props) => (
                  <AccesoriesPrimero
                    {...props}
                    datosArray={datosUsuario.accesories}
                  />
                )}
              /> */}
              <Route
                exact
                path="/audio"
                render={(props) =>
                  datosUsuario.recursos !== undefined && (
                    <Grid container spacing={2} style={{ padding: 20 }}>
                      {datosUsuario.recursos.map(
                        (dato) =>
                          dato.categoria === "audio" && (
                            <Resources {...props} datosArray={dato} />
                          )
                      )}
                    </Grid>
                  )
                }
              />

              <Route
                exact
                path="/comments"
                render={(props) =>
                  datosUsuario.recursos !== undefined && (
                    <Grid container spacing={2} style={{ padding: 20 }}>
                      {datosUsuario.recursos.map(
                        (dato) =>
                          dato.categoria === "comments" && (
                            <Resources {...props} datosArray={dato} />
                          )
                      )}
                    </Grid>
                  )
                }
              />

              <Route
                exact
                path="/image"
                render={(props) =>
                  datosUsuario.recursos !== undefined && (
                    <Grid container spacing={2} style={{ padding: 20 }}>
                      {datosUsuario.recursos.map(
                        (dato) =>
                          dato.categoria === "image" && (
                            <Resources {...props} datosArray={dato} />
                          )
                      )}
                    </Grid>
                  )
                }
              />

              <Route
                exact
                path="/test"
                render={(props) =>
                  datosUsuario.recursos !== undefined && (
                    <Grid container spacing={2} style={{ padding: 20 }}>
                      {datosUsuario.recursos.map(
                        (dato) =>
                          dato.categoria === "test" && (
                            <Resources {...props} datosArray={dato} />
                          )
                      )}
                    </Grid>
                  )
                }
              />

              <Route
                exact
                path="/video"
                render={(props) =>
                  datosUsuario.recursos !== undefined && (
                    <Grid container spacing={2} style={{ padding: 20 }}>
                      {datosUsuario.recursos.map(
                        (dato) =>
                          dato.categoria === "video" && (
                            <Resources {...props} datosArray={dato} />
                          )
                      )}
                    </Grid>
                  )
                }
              />

              <Route
                exact
                path="/sounds-basket"
                render={(props) => <HuevosComponent {...props} />}
              />
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
      </div>
    </Router>
  );
};

export default Markup;
