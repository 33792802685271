import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

const Resources = ({datosArray}) => {
    return (
        <>
       
        {/**Component de los grupos */}
        
       <Grid item  md={4} lg={3}>
         <CardActionArea onClick={()=> window.open(datosArray.archivo)}>
            <Card style={{ textAlign:'center'}}>
                <CardContent>
            <Typography gutterBottom variant="h6" component="h2">
            {datosArray.titulo}
          </Typography>
          <Typography component="div">
      <Box textAlign="center" fontSize={16} m={1} style={{marginBottom:20}}>
        <Grid>
        <img src={`/imagenes/categorias/${datosArray.categoria}.png`}></img>
        </Grid>
      </Box>
      </Typography>


         
          <Typography component="div">
      <Box textAlign="center" fontSize={12} m={1} style={{marginBottom:20}}>
        {'Click to open'}
      </Box>
      </Typography>

          </CardContent>
                </Card>
                </CardActionArea>
         
        
       
         

        </Grid>
        
        </>
      );
}
 
export default Resources;