import React, {useState} from 'react';
import useSound from 'use-sound';


const HuevosComponent = () => {

    let [sonidoActual, setSonidoActual] = useState('/imagenes/sonidos/a1.mp3');

    const [playMaster,{stop}] = useSound(sonidoActual);   

    const numerosAmarillos = [1,2,3,4,5,6,7,8]
    const numerosCafes = [1,2,3,4,5,6,7,8,9,10,11,12]
    const numerosMorados = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]

   


    return ( 
        <div style={{padding:20}}>
            

            {numerosAmarillos.map((dato, index)=>(
                 <img src={`/imagenes/huevos/a${dato}.png`} style={{width:120,height:120}} onClick={()=>{
                    
                     
                     playMaster();
                     
                    
                    }}
                    onMouseOver={()=>{setSonidoActual(`/imagenes/sonidos/a${dato}.mp3`);}}
                    key={index}></img>

            ))}
             {numerosCafes.map((dato, index)=>(
                 <img src={`/imagenes/huevos/c${dato}.png`} style={{width:120,height:120}} onClick={()=>{
                    
                     
                    playMaster();
                    
                   
                   }}
                   onMouseOver={()=>{setSonidoActual(`/imagenes/sonidos/c${dato}.mp3`);}} key={index}></img>

            ))}

             {numerosMorados.map((dato, index)=>(
                 <img src={`/imagenes/huevos/m${dato}.png`} style={{width:120,height:120}} onClick={()=>{
                    
                     
                    playMaster();
                    
                   
                   }}
                   onMouseOver={()=>{setSonidoActual(`/imagenes/sonidos/m${dato}.mp3`);}} key={index}></img>

            ))}
        
        </div>
     );
}
 
export default HuevosComponent;