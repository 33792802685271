import React from "react";




const Home = ({datosArray}) => {
   function SampleNextArrow(props) {
      const { onClick } = props;
      return (
         <div className="owl-next" onClick={onClick} style={{ zIndex: 99 }}>
            <i className="fa fa-caret-right" />
         </div>
      );
   }

   function SamplePrevArrow(props) {
      const { onClick } = props;
      return (
         <div
            className="owl-prev disabled"
            onClick={onClick}
            style={{ zIndex: 99 }}
         >
            <i className="fa fa-caret-left" />
         </div>
      );
   }

   const settings = {
      focusOnSelect: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      speed: 500,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
         {
            breakpoint: 1599,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 1,
            },
         },
         {
            breakpoint: 990,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
            },
         },
      ],
   };
   return (

      <>
      <div className="row">

      {/*Perfil */}
       <div className="col-xl-6 col-lg-6 col-sm-12">
      <div className="card overflow-hidden">
         <div
            className="text-center p-5 overlay-box"
            style={{ backgroundImage: `url(/imagenes/poker_logo.png)` }}
         >
            <img
              src='/img/avatar.svg'
               width="150"
               className="img-fluid rounded-circle"
               alt=""
            />
            <h3 className="mt-3 mb-0 text-white">{datosArray.nombre}</h3>
         </div>
         <div className="card-body">
            <div className="row text-center">
               <div className="col-6">
                  <div className="bgl-primary rounded p-3">
                     <h4 className="mb-0">Success Levels</h4>
                     <small>0/6</small>
                  </div>
               </div>
               <div className="col-6">
                  <div className="bgl-primary rounded p-3">
                     <h4 className="mb-0">Score</h4>
                     <small>0</small>
                  </div>
               </div>
            </div>
         </div>
        
      </div>
      </div>


        {/*Datos de nivel actual etc.. */}
      <div className="col-xl-6 col-lg-6 col-sm-12">
         <div className='row'>
         <div className="col-xl-6 col-lg-6 col-sm-12">
         
      <div className="widget-stat card bg-secondary">
                  <div className="card-body p-4">
                     <div className="media">
                        <span className="mr-3">
                           <i className="la la-graduation-cap"></i>
                        </span>
                        <div className="media-body text-white">
                           <p className="mb-1">Current level</p>
                           <h3 className="text-white">{datosArray.cursoActivo === false ? 'Not assigned' : datosArray.cursoActual}</h3>
                           
                        </div>
                     </div>
                  </div>
               </div>
               </div>
                  
                   <div className="col-xl-6 col-lg-6 col-sm-12">
                 <div className="widget-stat card bg-warning">
                  <div className="card-body p-4">
                     <div className="media">
                        <span className="mr-3">
                           <i className="flaticon-381-notebook"></i>
                        </span>
                        <div className="media-body text-white">
                           <p className="mb-1">Assigned teacher:</p>
                           <p className="text-white mb-1">{datosArray.cursoActivo === false ? 'Not assigned' : datosArray.nombreMaestro}</p>
                           
                        </div>
                     </div>
                  </div>
               </div>
               </div>
               

   </div>
   </div>
   </div>


        {/*Barras de progreso */}
        <div className='row'>


        { datosArray.calificaciones &&
        
        datosArray.calificaciones.map(dato=>(
           <>

            <div className="col-lg-6" style={{height:'fit-content'}} >
   <div className="card">
      <div className="card-body" style={{paddingBottom:40}}>
         <div className="media distance-bx align-items-center">
            <span className="icon bg-warning shadow-warning mr-3">
               <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
               >
                  <g clipPath="url(#clip1)">
                     <path
                        d="M0.988957 17.0741C0.328275 17.2007 -0.104585 17.8386 0.0219823 18.4993C0.133362 19.0815 0.644694 19.4865 1.21678 19.4865C1.29272 19.4865 1.37119 19.4789 1.44713 19.4637L6.4592 18.5018C6.74524 18.4461 7.00091 18.2917 7.18316 18.0639L9.33481 15.3503L8.61593 14.9832C8.08435 14.7149 7.71475 14.2289 7.58818 13.6391L5.55804 16.1983L0.988957 17.0741Z"
                        fill="white"
                     />
                     <path
                        d="M18.84 6.49306C20.3135 6.49306 21.508 5.29854 21.508 3.82502C21.508 2.3515 20.3135 1.15698 18.84 1.15698C17.3665 1.15698 16.1719 2.3515 16.1719 3.82502C16.1719 5.29854 17.3665 6.49306 18.84 6.49306Z"
                        fill="white"
                     />
                     <path
                        d="M13.0179 3.15677C12.7369 2.86819 12.4762 2.75428 12.1902 2.75428C12.0864 2.75428 11.9826 2.76947 11.8712 2.79479L7.29203 3.88073C6.6592 4.03008 6.26937 4.66545 6.41872 5.29576C6.54782 5.83746 7.02877 6.20198 7.56289 6.20198C7.65404 6.20198 7.74514 6.19185 7.8363 6.16907L11.7371 5.24513C11.9902 5.52611 13.2584 6.90063 13.4888 7.14364C11.8763 8.87002 10.2639 10.5939 8.65137 12.3202C8.62605 12.3481 8.60329 12.3759 8.58049 12.4038C8.10966 13.0037 8.25397 13.9454 8.96275 14.3023L13.9064 16.826L11.3397 20.985C10.9878 21.5571 11.165 22.3064 11.7371 22.6608C11.9371 22.7848 12.1573 22.843 12.375 22.843C12.7825 22.843 13.1824 22.638 13.4128 22.2659L16.6732 16.983C16.8529 16.6919 16.901 16.34 16.8074 16.0135C16.7137 15.6844 16.4884 15.411 16.1821 15.2566L12.8331 13.553L16.3543 9.78636L19.0122 12.0393C19.2324 12.2266 19.5032 12.3177 19.7716 12.3177C20.0601 12.3177 20.3487 12.2114 20.574 12.0038L23.6243 9.16112C24.1002 8.71814 24.128 7.97392 23.685 7.49803C23.4521 7.24996 23.1383 7.12339 22.8244 7.12339C22.5383 7.12339 22.2497 7.22717 22.0245 7.43727L19.7412 9.56107C19.7386 9.56361 14.0178 4.18196 13.0179 3.15677Z"
                        fill="white"
                     />
                  </g>
                  <defs>
                     <clipPath id="clip1">
                        <rect width={24} height={24} fill="white" />
                     </clipPath>
                  </defs>
               </svg>
               <p style={{marginBottom:5, width:'fit-content'}}>{dato.nombre}</p>
            </span>
            <div className="media-body">
               <h6 className="fs-18  mb-3" style={{color:'white'}}>
               {dato.nombre}
                  <span className="pull-right fs-14 text-dark">
                     100
                  </span>
               </h6>
               <div className="progress" style={{ height: 9 }}>
                  <div
                     className="progress-bar bg-warning progress-animated"
                     style={{ width: `${dato.calificacion}%`, height: 9 }}
                     role="progressbar"
                  >
                     <span className="sr-only">55% Complete</span>
                     <span className="bg-warning arrow" />
                     <span className="font-w600 counter-bx text-black">
                        <strong className="counter font-w600">
                        {dato.calificacion}
                        </strong>
                        
                     </span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>

   
<div className="col-lg-6" >
   <div className="card">
      <div className="card-body" style={{paddingBottom:40}}>
         <div className="media distance-bx align-items-center ">
            <div className='column'>
               <div>
         <p style={{margin:0}}>{dato.nombre+' comment:'}</p>
         </div>
       
            <div>
            <p>{dato.comentario}</p>
            </div>
            </div>
         </div>
      </div>
   </div>
</div>
</>

        ))
        
        }
  

  
   {/**
    {   
      datosArray.niveles &&
         datosArray.niveles.map(dato=>(

            
   <div className="col-lg-6" >
   <div className="card">
      <div className="card-body" style={{paddingBottom:40}}>
         <div className="media distance-bx align-items-center">
            <span className="icon bg-warning shadow-warning mr-3">
               <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
               >
                  <g clipPath="url(#clip1)">
                     <path
                        d="M0.988957 17.0741C0.328275 17.2007 -0.104585 17.8386 0.0219823 18.4993C0.133362 19.0815 0.644694 19.4865 1.21678 19.4865C1.29272 19.4865 1.37119 19.4789 1.44713 19.4637L6.4592 18.5018C6.74524 18.4461 7.00091 18.2917 7.18316 18.0639L9.33481 15.3503L8.61593 14.9832C8.08435 14.7149 7.71475 14.2289 7.58818 13.6391L5.55804 16.1983L0.988957 17.0741Z"
                        fill="white"
                     />
                     <path
                        d="M18.84 6.49306C20.3135 6.49306 21.508 5.29854 21.508 3.82502C21.508 2.3515 20.3135 1.15698 18.84 1.15698C17.3665 1.15698 16.1719 2.3515 16.1719 3.82502C16.1719 5.29854 17.3665 6.49306 18.84 6.49306Z"
                        fill="white"
                     />
                     <path
                        d="M13.0179 3.15677C12.7369 2.86819 12.4762 2.75428 12.1902 2.75428C12.0864 2.75428 11.9826 2.76947 11.8712 2.79479L7.29203 3.88073C6.6592 4.03008 6.26937 4.66545 6.41872 5.29576C6.54782 5.83746 7.02877 6.20198 7.56289 6.20198C7.65404 6.20198 7.74514 6.19185 7.8363 6.16907L11.7371 5.24513C11.9902 5.52611 13.2584 6.90063 13.4888 7.14364C11.8763 8.87002 10.2639 10.5939 8.65137 12.3202C8.62605 12.3481 8.60329 12.3759 8.58049 12.4038C8.10966 13.0037 8.25397 13.9454 8.96275 14.3023L13.9064 16.826L11.3397 20.985C10.9878 21.5571 11.165 22.3064 11.7371 22.6608C11.9371 22.7848 12.1573 22.843 12.375 22.843C12.7825 22.843 13.1824 22.638 13.4128 22.2659L16.6732 16.983C16.8529 16.6919 16.901 16.34 16.8074 16.0135C16.7137 15.6844 16.4884 15.411 16.1821 15.2566L12.8331 13.553L16.3543 9.78636L19.0122 12.0393C19.2324 12.2266 19.5032 12.3177 19.7716 12.3177C20.0601 12.3177 20.3487 12.2114 20.574 12.0038L23.6243 9.16112C24.1002 8.71814 24.128 7.97392 23.685 7.49803C23.4521 7.24996 23.1383 7.12339 22.8244 7.12339C22.5383 7.12339 22.2497 7.22717 22.0245 7.43727L19.7412 9.56107C19.7386 9.56361 14.0178 4.18196 13.0179 3.15677Z"
                        fill="white"
                     />
                  </g>
                  <defs>
                     <clipPath id="clip1">
                        <rect width={24} height={24} fill="white" />
                     </clipPath>
                  </defs>
               </svg>
               <p>{dato.nombre}</p>
            </span>
            <div className="media-body">
               <h6 className="fs-18  mb-3" style={{color:'white'}}>
                  Running
                  <span className="pull-right fs-14 text-dark">
                     100%
                  </span>
               </h6>
               <div className="progress" style={{ height: 9 }}>
                  <div
                     className="progress-bar bg-warning progress-animated"
                     style={{ width: `${dato.status}%`, height: 9 }}
                     role="progressbar"
                  >
                     <span className="sr-only">55% Complete</span>
                     <span className="bg-warning arrow" />
                     <span className="font-w600 counter-bx text-black">
                        <strong className="counter font-w600">
                          {dato.status}
                        </strong>
                        %
                     </span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>

   
            

         ))
      }
    * 
    * 
    */}
            
      

               
           
            </div>





        {/* 
          <div className="row">
        

            <div className="col-xl col-md-6">
               <div className="card">
                  <div className="card-body p-4">
                     <div className="d-inline-block mb-4 ml--12 position-relative donut-chart-sale">
                        <span
                           className="donut1"
                           data-peity='{ "fill": ["rgb(192, 255, 134)", "rgba(255, 255, 255, 1)"],   "innerRadius": 45, "radius": 10}'
                           style={{ display: "none" }}
                        >
                           4/8
                        </span>
                        <svg className="peity" height={110} width={110}>
                           <path
                              d="M 55 0 A 55 55 0 0 1 55 110 L 55 100 A 45 45 0 0 0 55 10"
                              data-value={4}
                              fill="rgb(192, 255, 134)"
                           />
                           <path
                              d="M 55 110 A 55 55 0 0 1 54.99999999999999 0 L 54.99999999999999 10 A 45 45 0 0 0 55 100"
                              data-value={4}
                              fill="rgba(255, 255, 255, 1)"
                           />
                        </svg>
                        <small className="text-primary">
                           <svg
                              width={40}
                              height={40}
                              viewBox="0 0 40 40"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                           >
                              <path
                                 d="M39.9353 18.3544C39.8731 18.1666 38.3337 13.75 32.5 13.75C25.9703 13.75 22.8666 17.9659 21.795 19.8719C20.6306 19.1822 19.1838 18.75 17.5 18.75C15.7922 18.75 14.35 19.1375 13.1275 19.7072C13.5697 16.695 13.6987 13.1119 13.7353 11.25H17.5C17.9175 11.25 18.3081 11.0413 18.54 10.6934L21.04 6.94344C21.4075 6.39156 21.2806 5.64813 20.7494 5.25031C18.3166 3.42531 15.1269 1.25 13.75 1.25C11.6137 1.25 6.95688 6.24344 5.16469 9.38C0.0584378 18.3153 0 31.925 0 32.5C0 32.8797 0.172188 33.2391 0.46875 33.4759C7.56469 39.1522 15.7519 40 20 40C23.3716 40 29.9756 39.4391 36.3306 35.6834C38.5938 34.3456 40 31.8706 40 29.2244V18.75C40 18.6156 39.9781 18.4822 39.9353 18.3544ZM37.5 29.2244C37.5 30.9912 36.565 32.6419 35.0584 33.5316C29.2162 36.9844 23.1166 37.5 20 37.5C16.9178 37.5 9.15156 36.9453 2.51094 31.8981C2.58406 29.19 3.14094 17.96 7.33531 10.62C9.09187 7.54813 12.7112 4.16312 13.7722 3.76562C14.4606 3.96406 16.4566 5.23219 18.2972 6.55125L16.8309 8.75H12.5C11.8091 8.75 11.25 9.30969 11.25 10C11.25 10.0822 11.2344 17.9659 10.185 21.6878C9.46375 22.3391 8.88656 22.9872 8.43125 23.4994C8.2175 23.7403 8.02969 23.9522 7.86594 24.1166C7.3775 24.605 7.3775 25.3959 7.86594 25.8841C8.35437 26.3722 9.14531 26.3725 9.63344 25.8841C9.82625 25.6913 10.0472 25.4441 10.3 25.1603C11.6003 23.6975 13.7756 21.25 17.5 21.25C20.5884 21.25 22.5 23.1966 22.5 25C22.5 25.6903 23.0591 26.25 23.75 26.25C24.4409 26.25 25 25.6903 25 25C25 23.8181 24.5506 22.6022 23.7313 21.5581C24.1503 20.66 26.5119 16.25 32.5 16.25C35.99 16.25 37.2228 18.39 37.5 18.9922V29.2244Z"
                                 fill="white"
                              />
                           </svg>
                        </small>
                        <span className="circle bg-primary" />
                     </div>
                     <h2 className="fs-24 text-black font-w600 mb-0">42%</h2>
                     <span className="fs-14">Weekly Progress</span>
                  </div>
               </div>
           
               </div>
            

 
         </div>
        
        
        */}
        
      </>
   );
};

export default Home;
