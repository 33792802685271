import React, { Component } from "react";

/// Link
import { Link } from "react-router-dom";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Menu
import MetisMenu from "metismenujs";

///
import drump from "../../../images/card/drump.png";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }

  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {
  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");

    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }

    btn.addEventListener("click", toggleFunc);
  }
  render() {
    /// Path
    const path = window.location.pathname;

    /// Active menu
    let deshBoard = [
        "",
        "workout-statistic",
        "workout-plan",
        "distance-map",
        "diet-food-menu",
        "personal-record",
      ],
      app = [
        "app-profile",
        "app-calender",
        "email-compose",
        "email-inbox",
        "email-read",
        "ecom-product-grid",
        "ecom-product-list",
        "ecom-product-list",
        "ecom-product-order",
        "ecom-checkout",
        "ecom-invoice",
        "ecom-customers",
      ],
      charts = [
        "chart-flot",
        "chart-morris",
        "chart-chartjs",
        "chart-chartist",
        "chart-sparkline",
        "chart-peity",
      ],
      bootstrap = [
        "ui-accordion",
        "ui-badge",
        "ui-alert",
        "ui-button",
        "ui-modal",
        "ui-button-group",
        "ui-list-group",
        "ui-media-object",
        "ui-card",
        "ui-carousel",
        "ui-dropdown",
        "ui-popover",
        "ui-progressbar",
        "ui-tab",
        "ui-typography",
        "ui-pagination",
        "ui-grid",
      ],
      plugins = [
        "uc-select2",
        "uc-nestable",
        "uc-sweetalert",
        "uc-toastr",
        "uc-jqvmap",
        "uc-noui-slider",
      ],
      widget = ["widget"],
      forms = [
        "form-element",
        "form-wizard",
        "form-editor-summernote",
        "form-pickers",
        "form-validation-jquery",
      ],
      table = ["table-bootstrap-basic", "table-datatable-basic"];

    return (
      <div className="deznav">
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">
            <li
              className={`${
                deshBoard.includes(path.slice(1)) ? "mm-active" : ""
              }`}
            >
              {/*Accesories */}
              <Link to="/" className="ai-icon" aria-expanded="false">
                <i className="flaticon-381-home"></i>
                <span className="nav-text">Home</span>
              </Link>
            </li>
            <li
              className={`${forms.includes(path.slice(1)) ? "mm-active" : ""}`}
            >
              {/*Cards */}

              <Link className="has-arrow ai-icon" to="#" aria-expanded="false">
                <i className="flaticon-381-notebook"></i>
                <span className="nav-text">Cards</span>
              </Link>
              <ul aria-expanded="false">
                <li>
                  <Link to="/level-1">Level 1</Link>
                </li>
                <li>
                  <Link to="/level-2">Level 2</Link>
                </li>
                <li>
                  <Link to="/level-3">Level 3</Link>
                </li>
                <li>
                  <Link to="/level-4">Level 4</Link>
                </li>
                <li>
                  <Link to="/level-5">Level 5</Link>
                </li>
                <li>
                  <Link to="/level-6">Level 6</Link>
                </li>
              </ul>
            </li>
            {/*Game */}
            {/* <li className={`${app.includes(path.slice(1)) ? "mm-active" : ""}`}>
            

              <Link className="has-arrow ai-icon" to="#" aria-expanded="false">
                <i className="flaticon-381-play-button"></i>
                <span className="nav-text">Game</span>
              </Link>
              <ul aria-expanded="false">
                <li>
                  <Link to="/game-level-1">Level 1</Link>
                </li>
                <li>
                  <Link to="/game-level-2">Level 2</Link>
                </li>
                <li>
                  <Link to="/game-level-3">Level 3</Link>
                </li>
                <li>
                  <Link to="/game-level-4">Level 4</Link>
                </li>
                <li>
                  <Link to="/game-level-5">Level 5</Link>
                </li>
                <li>
                  <Link to="/game-level-6">Level 6</Link>
                </li>
              </ul>
            </li> */}
            <li className={`${app.includes(path.slice(1)) ? "mm-active" : ""}`}>
              {/*Accesories */}
              <Link to="accesories" className="ai-icon" aria-expanded="false">
                <i className="flaticon-381-note"></i>
                <span className="nav-text">Accesories</span>
              </Link>
            </li>
            <li
              className={`${forms.includes(path.slice(1)) ? "mm-active" : ""}`}
            >
              {/*Sounds Basket */}
              <Link
                to="sounds-basket"
                className="ai-icon"
                aria-expanded="false"
              >
                <i className="flaticon-381-speaker"></i>
                <span className="nav-text">Sounds Basket</span>
              </Link>
            </li>

            <li
              className={`${forms.includes(path.slice(1)) ? "mm-active" : ""}`}
            >
              {/*Resources */}

              <Link className="has-arrow ai-icon" to="#" aria-expanded="false">
                <i className="flaticon-381-file"></i>
                <span className="nav-text">Resources</span>
              </Link>
              <ul aria-expanded="false">
                <li>
                  <Link to="/audio">Audio</Link>
                </li>
                <li>
                  <Link to="/comments">Comments</Link>
                </li>
                <li>
                  <Link to="/image">Image</Link>
                </li>
                <li>
                  <Link to="/test">Test</Link>
                </li>
                <li>
                  <Link to="/video">Video</Link>
                </li>
              </ul>
            </li>

            <li
              className={`${forms.includes(path.slice(1)) ? "mm-active" : ""}`}
            ></li>
          </MM>
        </PerfectScrollbar>
      </div>
    );
  }
}

export default SideBar;
