import React, {useState, useEffect} from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import './login.css'
import { makeStyles } from '@material-ui/core/styles';
import firebase from 'firebase/app';
import 'firebase/auth';
import {firebaseApp} from './FireBase';
import 'firebase/firestore';
const db = firebaseApp.firestore(firebaseApp);

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
   
  },
}));



const InicioLogin = (props) => {

     const classes = useStyles();
    const [datos, setDatos] = useState({
      email: '',
      password: ''
    })

    const [datosNuevos, setDatosNuevos] = useState({
      emailNuevo: '',
      usuario: '',
      passwordNuevo: '',
      passwordNuevoRepetir: ''
    })

    const [open, setOpen] = useState(false);
    const [comprobadorAlerta, setComprobadorAlerta] = useState('');
    const [esCrearUsuario, setEsCrearUsuario] = useState(false);
    const [palabraAlert, setPalabraAlert] = useState("error");



    const onChange = e =>{
      e.preventDefault();
      setDatos({
        ...datos,
        [e.target.name]: e.target.value
      })

    }

    const onChangeNuevoUsuario = e =>{
      e.preventDefault();
      setDatosNuevos({
        ...datosNuevos,
        [e.target.name]: e.target.value
      })

    }

    const iniciarApp = async(e)=>{
      e.preventDefault();

      setOpen(true);
      await firebase.auth().signInWithEmailAndPassword(datos.email, datos.password).then((datos)=>{
         // props.history.push('/dashboard');
         db.collection('usuarios').where('usuarioId','==',datos.user.uid).get().then((resultado)=>{
 
          resultado.forEach(doc=>{
             
              if(doc.data().usuarioActivo === true){
                props.history.push('/dashboard');
              }else{
                setComprobadorAlerta('Tu usuario no ah sido validado con tu profesor contactate para mas informacion');
              }
          })

         })
        

      }).catch((e)=>{

        if(e.code === "auth/user-not-found"){
          setComprobadorAlerta('No existe ningun usuario con ese correo');
        }else if(e.code === "auth/invalid-email"){
          setComprobadorAlerta('El correo esta mal escrito');
        }else if(e.code === "auth/wrong-password"){
          setComprobadorAlerta('La contraseña es incorrecta');

        }

       
      })

      setOpen(false);


    }
    
    const crearUsuario = async(e)=>{
      e.preventDefault();

          //validar el nombre del usuario
    if(!datosNuevos.usuario){
      setComprobadorAlerta("El nombre es obligatorio");
      return
  }

  //Validar el email
  if(!datosNuevos.emailNuevo){
    setComprobadorAlerta("El email es obligatorio");
    return;
  }else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(datosNuevos.emailNuevo)){
    setComprobadorAlerta('Email no valido');
    return

  }

  //Validar el password
  if(!datosNuevos.passwordNuevo){
    setComprobadorAlerta('El password es obligatorio');
    return;

  }else if(datosNuevos.passwordNuevo.length < 6){
    setComprobadorAlerta('El password debe ser de almenos 6 caracteres');
    return;
  }

  if(datosNuevos.passwordNuevo !== datosNuevos.passwordNuevoRepetir){
    setComprobadorAlerta('Las contraseñas no son iguales');
    return;
  }
     setOpen(true);
     const nuevoUsuario = await firebase.auth().createUserWithEmailAndPassword(datosNuevos.emailNuevo, datosNuevos.passwordNuevo);
     await nuevoUsuario.user.updateProfile({
          displayName: datosNuevos.usuario,
     })

     await db.collection('usuarios').add({
       usuarioId: nuevoUsuario.user.uid,
       nombre: datosNuevos.usuario,
       niveles:[
         {nombre: 'level 1', number: 1, status: 0, completed: false},
         {nombre: 'level 2', number: 2, status: 0, completed: false},
         {nombre: 'level 3', number: 3, status: 0, completed: false},
         {nombre: 'level 4', number: 4, status: 0, completed: false},
         {nombre: 'level 5', number: 5, status: 0, completed: false},
         {nombre: 'level 6', number: 6, status: 0, completed: false}
       ],
       score: 0,
       cursoActivo: false,
       calificaciones:[
         {nombre: 'Reading', calificacion:0, comentario:''},
         {nombre: 'Writing', calificacion:0, comentario:''},
         {nombre: 'Speaking', calificacion:0, comentario:''},
         {nombre: 'Listening', calificacion:0, comentario:''},
       ],
       nombreMaestro: '',
       recursos: [],
       cursoActual: '',
       nombreGrupo: '',
       usuarioActivo: false,
       cartas:{
         level1:[
          {desbloqueada: false,
           numeroCartas: 7,
           cartas:[false, false, false,false,false,false,false],
           categoria: 'person',
           ruta: '/imagenes/cards/1_step/desbloqueadas/person/',
           subcategoria: []
          },
          {desbloqueada: false,
            numeroCartas: 3,
            cartas:[false, false, false],
            categoria: 'verb-to-be',
            ruta: '/imagenes/cards/1_step/desbloqueadas/verb-to-be/',
            subcategoria: []
           },
           {desbloqueada: false,
            numeroCartas: 3,
            cartas:[false, false, false],
            categoria: 'description',
            ruta: '/imagenes/cards/1_step/desbloqueadas/description/',
            subcategoria: [
              {
                desbloqueada: false,
                numeroCartas: 44,
                cartas:[false, false, false, false, false,
                  false, false, false, false, false,
                  false, false, false, false, false,
                  false, false, false, false, false,
                  false, false, false, false, false,
                  false, false, false, false, false,
                  false, false, false, false, false,
                  false, false, false, false, false,
                  false,false,false,false

                ],
                categoria: 'general',
                ruta: '/imagenes/cards/1_step/desbloqueadas/description/general/',
                subcategoria: []
              },
              {
                desbloqueada: false,
                numeroCartas: 11,
                cartas:[false, false, false, false, false,
                  false, false, false, false, false,
                  false
                ],
                categoria: 'color',
                ruta: '/imagenes/cards/1_step/desbloqueadas/description/color/',
                subcategoria: []
              },
             
              {
                desbloqueada: false,
                numeroCartas: 13,
                cartas:[false, false, false, false, false,
                  false, false, false, false, false,
                  false, false, false
                ],
                categoria: 'nationality',
                ruta: '/imagenes/cards/1_step/desbloqueadas/description/nationality/',
                subcategoria: []
              }

            ]
           },
           {desbloqueada: false,
            numeroCartas: 13,
            categoria: 'name',
            ruta: '/imagenes/cards/1_step/desbloqueadas/name/',
            subcategoria: [
              {
                desbloqueada: false,
                numeroCartas: 14,
                cartas:[false, false, false, false, false,
                  false, false, false, false, false,
                  false, false, false, false
                ],
                categoria: 'jobs',
                 ruta: '/imagenes/cards/1_step/desbloqueadas/name/jobs/',
                subcategoria: []
              },
              {
                desbloqueada: false,
                numeroCartas: 13,
                cartas:[false, false, false, false, false,
                  false, false, false
                ],
                categoria: 'school-supplies',
                 ruta: '/imagenes/cards/1_step/desbloqueadas/name/school-supplies/',
                subcategoria: []
              },
              {
                desbloqueada: false,
                numeroCartas: 5,
                categoria: 'food',
                 ruta: '/imagenes/cards/1_step/desbloqueadas/name/food/',
                subcategoria: [
                  {
                    desbloqueada: false,
                    numeroCartas: 3,
                    cartas:[false, false, false],
                    categoria: 'drinks',
                     ruta: '/imagenes/cards/1_step/desbloqueadas/name/food/drinks/',
                    subcategoria: []
                  },
                  {
                    desbloqueada: false,
                    numeroCartas: 10,
                    cartas:[false, false, false, false, false,
                      false, false, false, false, false],
                    categoria: 'fruits-and-vegetables',
                     ruta: '/imagenes/cards/1_step/desbloqueadas/name/food/fruits-and-vegetables/',
                    subcategoria: []
                  },
                  {
                    desbloqueada: false,
                    numeroCartas: 5,
                    cartas:[false, false, false, false, false],
                    categoria: 'meat-and-dairy',
                     ruta: '/imagenes/cards/1_step/desbloqueadas/name/food/meat-and-dairy/',
                    subcategoria: []
                  },
                 
                  {
                    desbloqueada: false,
                    numeroCartas: 1,
                    cartas:[false],
                    categoria: 'sweets',
                     ruta: '/imagenes/cards/1_step/desbloqueadas/name/food/sweets/',
                    subcategoria: []
                  },
                  {
                    desbloqueada: false,
                    numeroCartas: 11,
                    cartas:[false, false, false, false, false,
                      false, false, false, false, false, false],
                    categoria: 'others',
                     ruta: '/imagenes/cards/1_step/desbloqueadas/name/food/others/',
                    subcategoria: []
                  }
                ]
              },
              {
                desbloqueada: false,
                numeroCartas: 13,
                cartas:[false, false, false, false, false,
                  false, false, false],
                categoria: 'language',
                 ruta: '/imagenes/cards/1_step/desbloqueadas/name/language/',
                subcategoria: []
              },
              {
                desbloqueada: false,
                numeroCartas: 11,
                cartas:[false, false, false, false, false,
                  false, false, false, false, false, false],
                categoria: 'personal',
                 ruta: '/imagenes/cards/1_step/desbloqueadas/name/personal/',
                subcategoria: []
              },
              {
                desbloqueada: false,
                numeroCartas: 31,
                cartas:[false, false, false, false, false,
                  false, false, false, false, false,
                  false, false, false, false, false,
                  false, false, false, false, false,
                  false, false, false, false, false,
                  false, false, false, false, false, false
                
                ],
                categoria: 'home',
                 ruta: '/imagenes/cards/1_step/desbloqueadas/name/home/',
                subcategoria: []
              },
              {
                desbloqueada: false,
                numeroCartas: 29,
                cartas:[false, false, false, false, false,
                  false, false, false, false, false,
                  false, false, false, false, false,
                  false, false, false, false, false,
                  false, false, false, false, false,
                  false, false, false, false
                
                ],
                categoria: 'numbers',
                 ruta: '/imagenes/cards/1_step/desbloqueadas/name/numbers/',
                subcategoria: []
              },
              {
                desbloqueada: false,
                numeroCartas: 10,
                cartas:[false, false, false, false, false,
                  false, false, false, false, false,
                
                
                ],
                categoria: 'family',
                 ruta: '/imagenes/cards/1_step/desbloqueadas/name/family/',
                subcategoria: []
              },
              
              {
                desbloqueada: false,
                numeroCartas: 16,
                cartas:[false, false, false, false, false,
                  false, false, false, false, false,
                  false, false, false, false, false,
                  false
                
                ],
                categoria: 'time',
                 ruta: '/imagenes/cards/1_step/desbloqueadas/name/time/',
                subcategoria: []
              },
              {
                desbloqueada: false,
                numeroCartas: 3,
                
                categoria: 'place',
                 ruta: '/imagenes/cards/1_step/desbloqueadas/name/place/',
                subcategoria: [
                  {
                    desbloqueada: false,
                    numeroCartas: 13,
                    cartas:[false, false, false, false, false,
                      false, false, false, false, false,
                      false, false, false
                    
                    ],
                    categoria: 'general',
                     ruta: '/imagenes/cards/1_step/desbloqueadas/name/place/general/',
                    subcategoria: []
                  },
                  {
                    desbloqueada: false,
                    numeroCartas: 13,
                    cartas:[false, false, false, false, false,
                      false, false, false, false, false,
                      false, false, false
                    
                    ],
                    categoria: 'country',
                     ruta: '/imagenes/cards/1_step/desbloqueadas/name/place/country/',
                    subcategoria: []
                  },
                  {
                    desbloqueada: false,
                    numeroCartas: 16,
                    cartas:[false, false, false, false, false,
                      false, false, false, false, false,
                      false, false, false, false, false,
                      false
                    ],
                    categoria: 'city',
                     ruta: '/imagenes/cards/1_step/desbloqueadas/name/place/city/',
                    subcategoria: []
                  }
                  
                  
                  
                ]
              },
           
              {
                desbloqueada: false,
                numeroCartas: 7,
                categoria: 'transportation',
                cartas:[false, false, false, false, false,
                  false, false
                ],
                 ruta: '/imagenes/cards/1_step/desbloqueadas/name/transportation/',
                subcategoria: []
              },
              {
                desbloqueada: false,
                numeroCartas: 7,
                cartas:[false, false, false, false, false,
                  false, false
                
                ],
                categoria: 'clothes',
                 ruta: '/imagenes/cards/1_step/desbloqueadas/name/clothes/',
                subcategoria: []
              },
              {
                desbloqueada: false,
                numeroCartas: 15,
                cartas:[false, false, false, false, false,
                  false, false, false, false, false,
                  false, false, false, false, false
                ],
                categoria: 'others',
                 ruta: '/imagenes/cards/1_step/desbloqueadas/name/others/',
                subcategoria: []
              },

            ]},
            {desbloqueada: false,
              numeroCartas: 1,
              cartas:[false ],
              categoria: 'super-verb',
              ruta: '/imagenes/cards/1_step/desbloqueadas/super-verb/',
              subcategoria: []
             },
             {desbloqueada: false,
              numeroCartas: 17,
              cartas:[false, false, false, false, false,
                false, false, false, false, false,
                false, false, false, false, false, false, false
              ],
              categoria: 'plug',
              ruta: '/imagenes/cards/1_step/desbloqueadas/plug/',
              subcategoria: []
             },
             {desbloqueada: false,
              numeroCartas: 50,
              cartas:[false, false, false, false, false,
                false, false, false, false, false,
                false, false, false, false, false,
                false, false, false, false, false,
                false, false, false, false, false,
                false, false, false, false, false,
                false, false, false, false, false,
                false, false, false, false, false,
                false, false, false, false, false,
                false, false, false, false, false,
              ],
              categoria: 'verb',
              ruta: '/imagenes/cards/1_step/desbloqueadas/verb/',
              subcategoria: []
             },
             {desbloqueada: false,
              numeroCartas: 14,
              cartas:[false, false, false, false, false,
                false, false, false, false, false,
                false, false, false, false, 
              ],
              categoria: 'extra',
              ruta: '/imagenes/cards/1_step/desbloqueadas/extra/',
              subcategoria: []
             },
             {desbloqueada: false,
              numeroCartas: 20,
              cartas:[false, false, false, false, false,
                false, false, false, false, false,
                false, false, false, false, false,
                false, false, false, false, false
              ],
              categoria: 'expression',
              ruta: '/imagenes/cards/1_step/desbloqueadas/expression/',
              subcategoria: []
             },
             {desbloqueada: false,
              numeroCartas: 9,
              cartas:[false, false, false, false, false,
                false, false, false, false
              ],
              
              categoria: 'wh',
              ruta: '/imagenes/cards/1_step/desbloqueadas/wh/',
              subcategoria: []
             }],
         level2:[
         
          {desbloqueada: false,
            numeroCartas: 21,
            cartas:[ false, false, false, false, false,
              false, false, false, false, false,
              false, false, false, false, false,
              false, false, false, false, false, false,],
            categoria: 'description',
            ruta: '/imagenes/cards/1_step_level_2/desbloqueadas/description/',
            subcategoria: []
           },
           {desbloqueada: false,
            numeroCartas: 6,
            cartas:[false, false, false,false,false,false],
            categoria: 'expression',
            ruta: '/imagenes/cards/1_step_level_2/desbloqueadas/expression/',
            subcategoria: []
           },
           {desbloqueada: false,
            numeroCartas: 4,
            cartas:[false, false, false,false],
            categoria: 'extra',
            ruta: '/imagenes/cards/1_step_level_2/desbloqueadas/extra/',
            subcategoria: []
           },
           {desbloqueada: false,
            numeroCartas: 14,
            cartas:[false, false, false, false, false, false, false, false, false, false, false, false,  false],
            categoria: 'name',
            ruta: '/imagenes/cards/1_step_level_2/desbloqueadas/name/',
            subcategoria: [
              {
                desbloqueada: false,
                numeroCartas: 9,
                cartas:[false, false, false, false, false,
                  false, false, false, false

                ],
                categoria: 'animals',
                ruta: '/imagenes/cards/1_step_level_2/desbloqueadas/name/animals/',
                subcategoria: []
              },
              {
                desbloqueada: false,
                numeroCartas: 29,
                cartas:[false, false, false, false, false,
                  false, false, false, false, false,
                  false, false, false, false, false,
                  false, false, false, false, false,
                  false, false, false, false, false,
                  false, false, false, false
                ],
                categoria: 'Body',
                ruta: '/imagenes/cards/1_step_level_2/desbloqueadas/name/Body/',
                subcategoria: []
              },
             
              {
                desbloqueada: false,
                numeroCartas: 10,
                cartas:[false, false, false, false, false,
                  false, false, false, false, false],
                categoria: 'diseases',
                ruta: '/imagenes/cards/1_step_level_2/desbloqueadas/name/diseases/',
                subcategoria: []
              },
             
              {
                desbloqueada: false,
                numeroCartas: 2,
                cartas:[false, false],
                categoria: 'Food',
                ruta: '/imagenes/cards/1_step_level_2/desbloqueadas/name/Food/',
                subcategoria: [
                  {
                    desbloqueada: false,
                    numeroCartas: 1,
                    cartas:[false],
                    categoria: 'Others',
                    ruta: '/imagenes/cards/1_step_level_2/desbloqueadas/name/Food/Others/',
                    subcategoria: []
                  },
                  {
                    desbloqueada: false,
                    numeroCartas: 2,
                    cartas:[false, false],
                    categoria: 'sweets',
                    ruta: '/imagenes/cards/1_step_level_2/desbloqueadas/name/Food/sweets/',
                    subcategoria: []
                  }
                ]
              },

              {
                desbloqueada: false,
                numeroCartas: 11,
                cartas:[false, false, false, false, false,
                  false, false, false, false, false, false],
                categoria: 'Instruments',
                ruta: '/imagenes/cards/1_step_level_2/desbloqueadas/name/Instruments/',
                subcategoria: []
              },
              {
                desbloqueada: false,
                numeroCartas: 6,
                cartas:[false, false, false, false, false,
                  false],
                categoria: 'jobs',
                ruta: '/imagenes/cards/1_step_level_2/desbloqueadas/name/jobs/',
                subcategoria: []
              },
              {
                desbloqueada: false,
                numeroCartas: 2,
                cartas:[false, false],
                categoria: 'kitchen-utensils',
                ruta: '/imagenes/cards/1_step_level_2/desbloqueadas/name/kitchen-utensils/',
                subcategoria: []
              },
              {
                desbloqueada: false,
                numeroCartas: 6,
                cartas:[false, false,false, false,false, false,],
                categoria: 'medications',
                ruta: '/imagenes/cards/1_step_level_2/desbloqueadas/name/medications/',
                subcategoria: []
              },
              {
                desbloqueada: false,
                numeroCartas: 3,
                cartas:[false, false,false],
                categoria: 'number',
                ruta: '/imagenes/cards/1_step_level_2/desbloqueadas/name/number/',
                subcategoria: []
              },
              {
                desbloqueada: false,
                numeroCartas: 34,
                cartas:[false, false,false,false,false,
                  false, false,false,false,false,
                  false, false,false,false,false,
                  false, false,false,false,false,
                  false, false,false,false,false,
                  false, false,false,false,false,
                  false,false,false,false
                ],
                categoria: 'Others',
                ruta: '/imagenes/cards/1_step_level_2/desbloqueadas/name/Others/',
                subcategoria: []
              },
              {
                desbloqueada: false,
                numeroCartas: 3,
                cartas:[false, false,false
                ],
                categoria: 'place',
                ruta: '/imagenes/cards/1_step_level_2/desbloqueadas/name/place/',
                subcategoria: [
                  {
                    desbloqueada: false,
                    numeroCartas: 2,
                    cartas:[false, false
                    ],
                    categoria: 'city',
                    ruta: '/imagenes/cards/1_step_level_2/desbloqueadas/name/place/city/',
                    subcategoria: []
                  },
                  {
                    desbloqueada: false,
                    numeroCartas: 24,
                    cartas:[false, false,false, false, false,
                      false, false,false, false, false,
                      false, false,false, false, false,
                      false, false,false, false, false,
                      false, false,false, false
                    ],
                    categoria: 'general',
                    ruta: '/imagenes/cards/1_step_level_2/desbloqueadas/name/place/general/',
                    subcategoria: []
                  },
                  {
                    desbloqueada: false,
                    numeroCartas: 4,
                    cartas:[false, false,false, false
                    ],
                    categoria: 'touristic-places',
                    ruta: '/imagenes/cards/1_step_level_2/desbloqueadas/name/place/touristic-places/',
                    subcategoria: []
                  },
                ]
              },
              {
                desbloqueada: false,
                numeroCartas: 11,
                cartas:[false, false,false, false, false,false, false, false,false,false, false],
                categoria: 'Sports',
                ruta: '/imagenes/cards/1_step_level_2/desbloqueadas/name/Sports/',
                subcategoria: []
              },
              {
                desbloqueada: false,
                numeroCartas: 13,
                cartas:[false, false,false, false, false,false, false, false,false,false, false, false, false],
                categoria: 'subjects',
                ruta: '/imagenes/cards/1_step_level_2/desbloqueadas/name/subjects/',
                subcategoria: []
              },
              {
                desbloqueada: false,
                numeroCartas: 3,
                cartas:[false, false,false],
                categoria: 'Time',
                ruta: '/imagenes/cards/1_step_level_2/desbloqueadas/name/Time/',
                subcategoria: [
                  {
                    desbloqueada: false,
                    numeroCartas: 4,
                    cartas:[false, false,false, false],
                    categoria: 'general',
                    ruta: '/imagenes/cards/1_step_level_2/desbloqueadas/name/Time/general/',
                    subcategoria: []
                  },
                  {
                    desbloqueada: false,
                    numeroCartas: 8,
                    cartas:[false, false,false, false,false, false,false, false],
                    categoria: 'Hollidays',
                    ruta: '/imagenes/cards/1_step_level_2/desbloqueadas/name/Time/Hollidays/',
                    subcategoria: []
                  },
                  {
                    desbloqueada: false,
                    numeroCartas: 12,
                    cartas:[false, false,false, false,false, false,false, false,false, false,false, false],
                    categoria: 'Months',
                    ruta: '/imagenes/cards/1_step_level_2/desbloqueadas/name/Time/Months/',
                    subcategoria: []
                  },
                  
                ]
              },

            ]
           },
           {desbloqueada: false,
            numeroCartas: 8,
            categoria: 'plug',
            ruta: '/imagenes/cards/1_step_level_2/desbloqueadas/plug/',
            subcategoria:[]
          
          
          },
            {desbloqueada: false,
              numeroCartas: 1,
              cartas:[false ],
              categoria: 'super-description',
              ruta: '/imagenes/cards/1_step_level_2/desbloqueadas/super-description/',
              subcategoria: []
             },
             {
               desbloqueada: false,
              numeroCartas: 2,
              cartas:[false, false ],
              categoria: 'super-verb',
              ruta: '/imagenes/cards/1_step_level_2/desbloqueadas/super-verb/',
              subcategoria: []
             },
             {
              desbloqueada: false,
             numeroCartas: 1,
             cartas:[false ],
             categoria: 'verb',
             ruta: '/imagenes/cards/1_step_level_2/desbloqueadas/verb/',
             subcategoria: [
              {
                desbloqueada: false,
               numeroCartas: 40,
               cartas:[false, false,false, false,false,
                false, false,false, false,false,
                false, false,false, false,false,
                false, false,false, false,false,
                false, false,false, false,false,
                false, false,false, false,false,
                false, false,false, false,false,
                false, false,false, false,false
              ],
               categoria: 'present',
               ruta: '/imagenes/cards/1_step_level_2/desbloqueadas/verb/present/',
               subcategoria: []
              },
             ]
            },
             {desbloqueada: false,
              numeroCartas: 2,
              cartas:[false, false
              ],
              categoria: 'verb-to-be',
              ruta: '/imagenes/cards/1_step_level_2/desbloqueadas/verb-to-be/',
              subcategoria: []
             },
             {desbloqueada: false,
              numeroCartas: 1,
              cartas:[false
              ],
              categoria: 'wh',
              ruta: '/imagenes/cards/1_step_level_2/desbloqueadas/wh/',
              subcategoria: []
             }
             ],
          level3:[
         
              {desbloqueada: false,
                numeroCartas: 2,
                cartas:[ false, false,],
                categoria: 'description',
                ruta: '/imagenes/cards/1_step_level_3/desbloqueadas/description/',
                subcategoria: [
                  {
                    desbloqueada: false,
                    numeroCartas: 22,
                    cartas:[false, false, false, false, false,
                      false, false, false, false, false,
                      false, false, false, false, false,
                      false, false, false, false, false,
                      false, false
                    ],
                    categoria: 'general',
                    ruta: '/imagenes/cards/1_step_level_3/desbloqueadas/description/general/',
                    subcategoria: []
                  },
                  {
                    desbloqueada: false,
                    numeroCartas: 2,
                    cartas:[false, false],
                    categoria: 'nationality',
                    ruta: '/imagenes/cards/1_step_level_3/desbloqueadas/description/nationality/',
                    subcategoria: []
                  },
                 
              
                ]
               },
               {desbloqueada: false,
                numeroCartas: 9,
                cartas:[false, false, false,false,false,false,false, false, false],
                categoria: 'expression',
                ruta: '/imagenes/cards/1_step_level_3/desbloqueadas/expression/',
                subcategoria: []
               },
               {desbloqueada: false,
                numeroCartas: 1,
                cartas:[false],
                categoria: 'extra',
                ruta: '/imagenes/cards/1_step_level_3/desbloqueadas/extra/',
                subcategoria: []
               },
               {desbloqueada: false,
                numeroCartas: 12,
                cartas:[false, false, false, false, false, false, false, false, false, false, false, false],
                categoria: 'name',
                ruta: '/imagenes/cards/1_step_level_3/desbloqueadas/name/',
                subcategoria: [
                  {
                    desbloqueada: false,
                    numeroCartas: 36,
                    cartas:[false, false, false, false, false,
                      false, false, false, false, false,
                      false, false, false, false, false,
                      false, false, false, false, false,
                      false, false, false, false, false,
                      false, false, false, false, false,
                      false, false, false, false, false, false   
                    ],
                    categoria: 'Animals',
                    ruta: '/imagenes/cards/1_step_level_3/desbloqueadas/name/Animals/',
                    subcategoria: []
                  },
                  {
                    desbloqueada: false,
                    numeroCartas: 3,
                    cartas:[false, false,false
                    ],
                    categoria: 'place',
                    ruta: '/imagenes/cards/1_step_level_3/desbloqueadas/name/place/',
                    subcategoria: [
                      {
                        desbloqueada: false,
                        numeroCartas: 6,
                        cartas:[false, false,false, false, false,false ],
                        categoria: 'general',
                        ruta: '/imagenes/cards/1_step_level_3/desbloqueadas/name/place/general/',
                        subcategoria: []
                      },
                      {
                        desbloqueada: false,
                        numeroCartas: 3,
                        cartas:[false, false,false],
                        categoria: 'country',
                        ruta: '/imagenes/cards/1_step_level_3/desbloqueadas/name/place/country/',
                        subcategoria: []
                      },
                      {
                        desbloqueada: false,
                        numeroCartas: 1,
                        cartas:[false],
                        categoria: 'touristic-places',
                        ruta: '/imagenes/cards/1_step_level_3/desbloqueadas/name/place/touristic-places/',
                        subcategoria: []
                      },
                    ]
                  },
                 
                  {
                    desbloqueada: false,
                    numeroCartas: 2,
                    cartas:[false, false],
                    categoria: 'home',
                    ruta: '/imagenes/cards/1_step_level_3/desbloqueadas/name/home/',
                    subcategoria: []
                  },
                  {
                    desbloqueada: false,
                    numeroCartas: 10,
                    cartas:[false, false,false,false,false, false, false,false,false,false],
                    categoria: 'family',
                    ruta: '/imagenes/cards/1_step_level_3/desbloqueadas/name/family/',
                    subcategoria: []
                  },
                 
                 
                  {
                    desbloqueada: false,
                    numeroCartas: 2,
                    cartas:[false, false],
                    categoria: 'food-and-drinks',
                    ruta: '/imagenes/cards/1_step_level_3/desbloqueadas/name/food-and-drinks/',
                    subcategoria: []
                  },
                  {
                    desbloqueada: false,
                    numeroCartas: 11,
                    cartas:[false, false, false, false, false,
                      false, false, false, false, false, false],
                    categoria: 'jobs',
                    ruta: '/imagenes/cards/1_step_level_3/desbloqueadas/name/jobs/',
                    subcategoria: []
                  },
                  {
                    desbloqueada: false,
                    numeroCartas: 4,
                    cartas:[false, false, false, false],
                    categoria: 'clothes',
                    ruta: '/imagenes/cards/1_step_level_3/desbloqueadas/name/clothes/',
                    subcategoria: []
                  },
                  {
                    desbloqueada: false,
                    numeroCartas: 26,
                    cartas:[false, false,false, false,false, false,false, false,false, false,
                      false, false,false, false,false, false,false, false,false, false,
                      false, false,false, false,false, false

                    ],
                    categoria: 'Others',
                    ruta: '/imagenes/cards/1_step_level_2/desbloqueadas/name/Others/',
                    subcategoria: []
                  },
                  {
                    desbloqueada: false,
                    numeroCartas: 5,
                    cartas:[false, false,false, false,false],
                    categoria: 'Sports',
                    ruta: '/imagenes/cards/1_step_level_3/desbloqueadas/name/Sports/',
                    subcategoria: []
                  },
                  {
                    desbloqueada: false,
                    numeroCartas: 4,
                    cartas:[false, false,false, false],
                    categoria: 'money',
                    ruta: '/imagenes/cards/1_step_level_3/desbloqueadas/name/money/',
                    subcategoria: []
                  },
                  {
                    desbloqueada: false,
                    numeroCartas: 9,
                    cartas:[false, false,false,false,false,
                      false, false,false,false
                    ],
                    categoria: 'material',
                    ruta: '/imagenes/cards/1_step_level_3/desbloqueadas/name/material/',
                    subcategoria: []
                  },
                  {
                    desbloqueada: false,
                    numeroCartas: 3,
                    cartas:[false, false,false
                    ],
                    categoria: 'enterteiment',
                    ruta: '/imagenes/cards/1_step_level_3/desbloqueadas/name/enterteiment/',
                    subcategoria: [
                      {
                        desbloqueada: false,
                        numeroCartas: 9,
                        cartas:[false, false, false, false, false, false, false, false, false],
                        categoria: 'tv-shows',
                        ruta: '/imagenes/cards/1_step_level_3/desbloqueadas/name/enterteiment/tv-shows/',
                        subcategoria: []
                      },
                      {
                        desbloqueada: false,
                        numeroCartas: 6,
                        cartas:[false, false,false, false, false,false],
                        categoria: 'movies',
                        ruta: '/imagenes/cards/1_step_level_3/desbloqueadas/name/enterteiment/movies/',
                        subcategoria: []
                      },
                      {
                        desbloqueada: false,
                        numeroCartas: 10,
                        cartas:[false, false,false, false,false, false,false, false, false, false
                        ],
                        categoria: 'music',
                        ruta: '/imagenes/cards/1_step_level_3/desbloqueadas/name/enterteiment/music/',
                        subcategoria: []
                      },
                    ]
                  }
                
                ]
               },
               {desbloqueada: false,
                numeroCartas: 3,
                cartas:[false, false, false ],
                categoria: 'plug',
                ruta: '/imagenes/cards/1_step_level_3/desbloqueadas/plug/',
                subcategoria:[]
              
              
              },
                {desbloqueada: false,
                  numeroCartas: 1,
                  cartas:[false ],
                  categoria: 'super-description',
                  ruta: '/imagenes/cards/1_step_level_3/desbloqueadas/super-description/',
                  subcategoria: []
                 },
                 {
                   desbloqueada: false,
                  numeroCartas: 1,
                  cartas:[false],
                  categoria: 'super-verb',
                  ruta: '/imagenes/cards/1_step_level_3/desbloqueadas/super-verb/',
                  subcategoria: []
                 },
                
              
                 {desbloqueada: false,
                  numeroCartas: 6,
                  cartas:[false,false, false,false, false,false],
                  categoria: 'wh',
                  ruta: '/imagenes/cards/1_step_level_3/desbloqueadas/wh/',
                  subcategoria: []
                 }
                 ],
          level4:[
         
                  {desbloqueada: false,
                    numeroCartas: 2,
                    cartas:[ false, false,],
                    categoria: 'description',
                    ruta: '/imagenes/cards/1_step_level_4/desbloqueadas/description/',
                    subcategoria: [
                      {
                        desbloqueada: false,
                        numeroCartas: 29,
                        cartas:[false, false, false, false, false,
                          false, false, false, false, false,
                          false, false, false, false, false,
                          false, false, false, false, false,
                          false, false, false, false, false,
                          false, false, false, false
                          
                        ],
                        categoria: 'general',
                        ruta: '/imagenes/cards/1_step_level_4/desbloqueadas/description/general/',
                        subcategoria: []
                      },
                      {
                        desbloqueada: false,
                        numeroCartas: 4,
                        cartas:[false, false, false, false],
                        categoria: 'nationality',
                        ruta: '/imagenes/cards/1_step_level_4/desbloqueadas/description/nationality/',
                        subcategoria: []
                      },
                     
                  
                    
                    
                    
                    ]
                   },
                   {desbloqueada: false,
                    numeroCartas: 4,
                    cartas:[false, false, false,false],
                    categoria: 'expression',
                    ruta: '/imagenes/cards/1_step_level_4/desbloqueadas/expression/',
                    subcategoria: []
                   },
                   {desbloqueada: false,
                    numeroCartas: 8,
                    cartas:[false, false, false, false, false, false, false, false],
                    categoria: 'extra',
                    ruta: '/imagenes/cards/1_step_level_4/desbloqueadas/extra/',
                    subcategoria: []
                   },
                   {desbloqueada: false,
                    numeroCartas: 1,
                    cartas:[false],
                    categoria: 'emotion',
                    ruta: '/imagenes/cards/1_step_level_4/desbloqueadas/emotion/',
                    subcategoria: []
                   },
                   {desbloqueada: false,
                    numeroCartas: 3,
                    cartas:[false, false, false ],
                    categoria: 'plug',
                    ruta: '/imagenes/cards/1_step_level_4/desbloqueadas/plug/',
                    subcategoria:[]
                  
                  
                  },
                    {desbloqueada: false,
                      numeroCartas: 2,
                      cartas:[false, false ],
                      categoria: 'super-description',
                      ruta: '/imagenes/cards/1_step_level_4/desbloqueadas/super-description/',
                      subcategoria: []
                     },
                     {
                       desbloqueada: false,
                      numeroCartas: 4,
                      cartas:[false, false, false, false],
                      categoria: 'super-verb',
                      ruta: '/imagenes/cards/1_step_level_4/desbloqueadas/super-verb/',
                      subcategoria: []
                     },
                    
                  
                     {desbloqueada: false,
                      numeroCartas: 1,
                      cartas:[false],
                      categoria: 'verb-to-be',
                      ruta: '/imagenes/cards/1_step_level_4/desbloqueadas/verb-to-be/',
                      subcategoria: []
                     }
                     ],
              
         level5:[false,false,false,false,false,false,false,false,false,false,false,false,false],
         level6:[false,false,false,false,false,false,false,false,false,false,false,false,false],
         level7:[false,false,false,false,false,false,false,false,false,false,false,false,false],
       },
       accesories:[false,false,false,false,false,false,false,false],
       accesories2:[false,false,false]
     })
     
     setPalabraAlert('success');
     setComprobadorAlerta('Usuario registrado espere la validacion de su profesor para acceder a la plataforma');
     setOpen(false);

    }

  return ( 
    <>
    <img class="wave" src="img/wave.png"/>
	<div class="container">
		<div class="img">
			<img src="img/bg.svg"/>
		</div>
		<div class="login-content">
    {esCrearUsuario === false ? 

    <>
			<form onSubmit={iniciarApp}>
				<img src="img/avatar.svg"/>
				<h2 class="title">Bienvenido</h2>
        {comprobadorAlerta !== '' && <Alert severity="error" style={{marginBottom:20}}>{comprobadorAlerta}</Alert>}

        {/*Iniciar Sesion */}

       
               
           		<div class="input-div one">
           		   <div class="i">
           		   		<i class="fas fa-user"></i>
           		   </div>
           		   <div class="div">
           		   		<h5>Usuario</h5>
           		   		<input type="text" class="input" onChange={onChange} name="email"/>
           		   </div>
           		</div>
           		<div class="input-div pass">
           		   <div class="i"> 
           		    	<i class="fas fa-lock"></i>
           		   </div>
           		   <div class="div">
           		    	<h5>Contraseña</h5>
           		    	<input type="password" class="input" onChange={onChange} name="password"/>
            	   </div>
            	</div>

              <div class="input-div pass" style={{display:'none'}}>
           		   <div class="i"> 
           		    	<i class="fas fa-lock"></i>
           		   </div>
           		   <div class="div">
           		    	<h5>Contraseña</h5>
           		    	<input type="password" class="input" onChange={onChange} name="password"/>
            	   </div>
            	</div>

              <div class="input-div pass" style={{display:'none'}}>
           		   <div class="i"> 
           		    	<i class="fas fa-lock"></i>
           		   </div>
           		   <div class="div">
           		    	<h5>Contraseña</h5>
           		    	<input type="password" class="input" onChange={onChange} name="password"/>
            	   </div>
            	</div>
              
            	<a href="#" className='a' onClick={()=>{setEsCrearUsuario(true); setComprobadorAlerta(''); setPalabraAlert('error')}}>¿No tienes usuario?</a>
            	<input type="submit" class="btn" value="Login"/>
              </form>
              </>
              :
              <>
              <form onSubmit={crearUsuario}>
				<img src="img/avatar.svg"/>
				<h2 class="title">Registrate</h2>
        {comprobadorAlerta !== '' && <Alert severity={palabraAlert} style={{marginBottom:20}}>{comprobadorAlerta}</Alert>}

        {/*Iniciar Sesion */}

             
              <div class="input-div one">
           		   <div class="i">
           		   		<i class="fas fa-user"></i>
           		   </div>
           		   <div class="div">
           		   		<h5>Correo</h5>
           		   		<input type="text" class="input" onChange={onChangeNuevoUsuario} name="emailNuevo"/>
           		   </div>

           		</div>

               <div class="input-div one">
           		   <div class="i">
           		   		<i class="fas fa-user"></i>
           		   </div>
           		   <div class="div">
           		   		<h5>Nombre completo</h5>
           		   		<input type="text" class="input" onChange={onChangeNuevoUsuario} name="usuario"/>
           		   </div>

           		</div>
           		<div class="input-div pass">
           		   <div class="i"> 
           		    	<i class="fas fa-lock"></i>
           		   </div>
           		   <div class="div">
           		    	<h5>Contraseña</h5>
           		    	<input type="password" class="input" onChange={onChangeNuevoUsuario} name="passwordNuevo"/>
            	   </div>
            	</div>

              <div class="input-div pass">
           		   <div class="i"> 
           		    	<i class="fas fa-lock"></i>
           		   </div>
           		   <div class="div">
           		    	<h5>Repetir contraseña</h5>
           		    	<input type="password" class="input" onChange={onChangeNuevoUsuario} name="passwordNuevoRepetir"/>
            	   </div>
            	</div>
            	<a href="#" className='a' onClick={()=>{setEsCrearUsuario(false);  setComprobadorAlerta(''); setPalabraAlert('error')}}>¿Ya tienes un usuario?</a>
            	<input type="submit" class="btn" value="Crear una cuenta"/>
              </form>
              </>

        }
            
        </div>
              <Backdrop className={classes.backdrop} open={open} >
          <CircularProgress color="inherit" />
        </Backdrop>
    </div>

    </>
   );
}
 
export default InicioLogin;