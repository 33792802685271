import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Cards3Segundo from './Cards3Segundo';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
  }));

const Cards3Primero = ({datosArray}) => {

    const classes = useStyles();

    const [numerador, setNumerador] = useState(0);
    const [arrayCartasGiro, setArrayCartasGiro] = useState([]);

    const [nivel1, setNivel1] = useState([{
      esSubcategoria: false,
      arrayNivel: [],
      ruta:''
    }]);

    const [nivel2, setNivel2] = useState([{
      esSubcategoria: false,
      arrayNivel: [],
      ruta:''
    }]);

    const [nivel3, setNivel3] = useState([{
      esSubcategoria: false,
      arrayNivel: [],
      ruta:''
    }]);

    const [nivel4, setNivel4] = useState([{
      esSubcategoria: false,
      arrayNivel: [],
      ruta:''
    }]);

    


    let imagenesVerb =[
      {
          frente: 'am.png' ,
          reverso: 'am 1.png'
      },
      {
          frente: 'are.png' ,
          reverso: 'are 1.png'
      },
      {
          frente: 'is.png' ,
          reverso: 'is 1.png'
      }
  ]

  let imagenesPerson =[
    {
        frente: 'he.png' ,
        reverso: 'he 1.png'
    },
    {
        frente: 'I.png' ,
        reverso: 'I 1.png'
    },
    {
        frente: 'she.png' ,
        reverso: 'she 1.png'
    },
    {
      frente: 'you.png' ,
      reverso: 'You 1.png'
  }
]

let imagenesDesciption =[
  {
      frente: 'beautiful.png' ,
      reverso: 'beautiful 1.png'
  },
  {
      frente: 'Big.png' ,
      reverso: 'big 1.png'
  },
  {
      frente: 'Old.png' ,
      reverso: 'old 1.png'
  },
  {
      frente: 'Short.png' ,
      reverso: 'short 1.png'
  },
  {
      frente: 'Small.png' ,
      reverso: 'small 1.png'
  },
  {
      frente: 'tall.png' ,
      reverso: 'tall 1.png'
  },
  {
      frente: 'Ugly.png' ,
      reverso: 'ugly 1.png'
  },
  {
      frente: 'young.png' ,
      reverso: 'young 1.png'
  }
]

    return (  
      <>
     {numerador !==0  ? <div style={{padding:20}} >
      <Button variant="contained" onClick={()=>{setNumerador(numerador-1)}}>Regresar</Button>
      </div> :
       <div style={{padding:20}} >
         
       </div>

    }
      <Grid
      spacing={5}
      container
      style={{paddingBottom:40}}
      direction="row"
      justify="center"
      alignItems="center"

      
    >

      {datosArray !== undefined?

     
               numerador === 0 ?
                <>

                {
                  datosArray.map((dato, i)=>(

                    dato.desbloqueada === false ?

                    <Grid item key={i} >
                    <input type="image" src={`/imagenes/cards/1_step/bloqueadas/${i+1}.png`}  style={{  boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',height:239, width:185, borderRadius:15}}/>
      
                    </Grid>
   
                    :

                    <Grid item key={i} >
                 <input type="image" src={`/imagenes/cards/1_step_level_3/desbloqueadas/${i+1}.png`} onClick={()=> {

                   let arrayMaster = [];

                   if(dato.subcategoria.length === 0){

                    dato.cartas.map((dato1,i)=>{
                      if(dato1 == true){
    
                      arrayMaster.push(
                        <Grid item class="flip-card" style={{margin:20}}>
                        <Grid item class="flip-card-inner">
                          <Grid item class="flip-card-front">
                            <img src={`${dato.ruta}${i+1}.png`} alt="Avatar"  style={{width: 213 , height: 280, borderRadius:15}}/>
                          </Grid>
                          <Grid class="flip-card-back">
                          <img src={`${dato.ruta}${i+1}_1.png`}  alt="Avatar" style={{width: 213 , height: 280,  borderRadius:15}}/>
                          </Grid>
                        </Grid>
                      </Grid>
                        )
    
                      }else{
    
                        arrayMaster.push(
                          <Grid item class="flip-card" style={{margin:20}}>
                          <Grid item class="flip-card-inner">
                            <Grid item class="flip-card-front">
                              <img src={`/imagenes/cards/1_step/bloqueadas/4.png`} alt="Avatar"  style={{width: 213 , height: 280, borderRadius:15}}/>
                            </Grid>
                            <Grid class="flip-card-back">
                            <img src={`/imagenes/cards/1_step/bloqueadas/4.png`}  alt="Avatar" style={{width: 213 , height: 280,  borderRadius:15}}/>
                            </Grid>
                          </Grid>
                        </Grid>
                          )
    
    
                      }
    
    
    
                        setNivel1({...nivel1,
                          ['esSubcategoria']: false,
                          ['arrayNivel']: arrayMaster});
    
                        })

                   }

                   if(dato.subcategoria.length !== 0){
                     
                    let arrayMaster2 = []
                      dato.subcategoria.map((dato)=>(

                          arrayMaster2.push(dato)
                      ))
                   

                      setNivel1({...nivel1,
                        ['esSubcategoria']: true,
                        ['ruta']: dato.ruta,
                        ['arrayNivel']: arrayMaster2
                        });

                   

                   }
                   
                   
                   setNumerador(numerador+1)}} style={{  boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',height:239, width:185, borderRadius:15}}/>
   
                 </Grid>


                  ))
                }
                
              

               
               
               
               </> :
               
               numerador === 1 ?
              <>
              
               
             {
             
             nivel1.esSubcategoria === false  ?
             nivel1.arrayNivel.map(dato=>(
               dato
             ))

             :
             
              nivel1.arrayNivel.map((dato, i)=>(

                dato.desbloqueada === false ?

                <Grid item key={i} >
                <input type="image" src={`/imagenes/cards/1_step/bloqueadas/4.png`}  style={{  boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',height:239, width:185, borderRadius:15}}/>
  
                </Grid>

                :

                <Grid item key={i} >
             <input type="image" src={`${nivel1.ruta}${i+1}.png`} onClick={()=> {

               let arrayMaster = [];

               if(dato.subcategoria.length === 0){

                dato.cartas.map((dato1,i)=>{
                  if(dato1 == true){

                  arrayMaster.push(
                    <Grid item class="flip-card" style={{margin:20}}>
                    <Grid item class="flip-card-inner">
                      <Grid item class="flip-card-front">
                        <img src={`${dato.ruta}${i+1}.png`} alt="Avatar"  style={{width: 213 , height: 280, borderRadius:15}}/>
                      </Grid>
                      <Grid class="flip-card-back">
                      <img src={`${dato.ruta}${i+1}_1.png`}  alt="Avatar" style={{width: 213 , height: 280,  borderRadius:15}}/>
                      </Grid>
                    </Grid>
                  </Grid>
                    )

                  }else{

                    arrayMaster.push(
                      <Grid item class="flip-card" style={{margin:20}}>
                      <Grid item class="flip-card-inner">
                        <Grid item class="flip-card-front">
                          <img src={`/imagenes/cards/1_step/bloqueadas/4.png`} alt="Avatar"  style={{width: 213 , height: 280,  borderRadius:15}}/>
                        </Grid>
                        <Grid class="flip-card-back">
                        <img src={`/imagenes/cards/1_step/bloqueadas/4.png`}  alt="Avatar" style={{width: 213 , height: 280,  borderRadius:15}}/>
                        </Grid>
                      </Grid>
                    </Grid>
                      )


                  }



                    setNivel2({...nivel2,
                      ['esSubcategoria']: false,
                      ['arrayNivel']: arrayMaster});

                    })

                

               }

               if(dato.subcategoria.length !== 0){

                for(let i = 0; i<dato.numeroCartas; i++){

                  let arrayMaster2 = []
                  dato.subcategoria.map((dato)=>(

                      arrayMaster2.push(dato)
                  ))
               

                  setNivel2({...nivel2,
                    ['esSubcategoria']: true,
                    ['ruta']: dato.ruta,
                    ['arrayNivel']: arrayMaster2
                    });

               
              

               }
              }
               
               setNumerador(numerador+1)}} style={{  boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',height:239, width:185, borderRadius:15}}/>

             </Grid>


              ))
            
            
             
            
            
            }
              
              </>

              :

              numerador === 2 ?
              <>
               {   nivel2.esSubcategoria === false  ?
             nivel2.arrayNivel.map(dato=>(
               dato
             ))

             :
             nivel2.arrayNivel.map((dato, i)=>(

              dato.desbloqueada === false ?

              <Grid item key={i} >
              <input type="image" src={`/imagenes/cards/1_step/bloqueadas/4.png`}  style={{  boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',height:239, width:185, borderRadius:15}}/>

              </Grid>

              :

              <Grid item key={i} >
           <input type="image" src={`${nivel2.ruta}${i+1}.png`} onClick={()=> {

             let arrayMaster = [];

             if(dato.subcategoria.length === 0){

              dato.cartas.map((dato1,i)=>{
                if(dato1 == true){

                arrayMaster.push(
                  <Grid item class="flip-card" style={{margin:20}}>
                  <Grid item class="flip-card-inner">
                    <Grid item class="flip-card-front">
                      <img src={`${dato.ruta}${i+1}.png`} alt="Avatar"  style={{width: 213 , height: 280, borderRadius:15}}/>
                    </Grid>
                    <Grid class="flip-card-back">
                    <img src={`${dato.ruta}${i+1}_1.png`}  alt="Avatar" style={{width: 213 , height: 280,  borderRadius:15}}/>
                    </Grid>
                  </Grid>
                </Grid>
                  )

                }else{

                  arrayMaster.push(
                    <Grid item class="flip-card" style={{margin:20}}>
                    <Grid item class="flip-card-inner">
                      <Grid item class="flip-card-front">
                        <img src={`/imagenes/cards/1_step/bloqueadas/4.png`} alt="Avatar"  style={{width: 213 , height: 280,  borderRadius:15}}/>
                      </Grid>
                      <Grid class="flip-card-back">
                      <img src={`/imagenes/cards/1_step/bloqueadas/4.png`}  alt="Avatar" style={{width: 213 , height: 280,  borderRadius:15}}/>
                      </Grid>
                    </Grid>
                  </Grid>
                    )


                }



                  setNivel3({...nivel3,
                    ['esSubcategoria']: false,
                    ['arrayNivel']: arrayMaster});

                  })

             }

             if(dato.subcategoria.length !== 0){

              for(let i = 0; i<dato.numeroCartas; i++){

                let arrayMaster2 = []
                dato.subcategoria.map((dato)=>(

                    arrayMaster2.push(dato)
                ))
             

                setNivel3({...nivel3,
                  ['esSubcategoria']: true,
                  ['ruta']: dato.ruta,
                  ['arrayNivel']: arrayMaster2
                  });

             
            

             }
            }
             
             setNumerador(numerador+1)}} style={{  boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',height:239, width:185, borderRadius:15}}/>

           </Grid>


            ))
          
          }
              
              </>

              :
              
              numerador === 3 ?
              <>
               {    nivel3.esSubcategoria === false  ?
             nivel3.arrayNivel.map(dato=>(
               dato
             ))

             :
             nivel3.arrayNivel.map((dato, i)=>(

              dato.desbloqueada === false ?

              <Grid item key={i} >
              <input type="image" src={`/imagenes/cards/1_step/bloqueadas/4.png`}  style={{  boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',height:239, width:185, borderRadius:15}}/>

              </Grid>

              :

              <Grid item key={i} >
           <input type="image" src={`${nivel2.ruta}${i+1}.png`} onClick={()=> {

             let arrayMaster = [];

             if(dato.subcategoria.length === 0){

              dato.cartas.map((dato1,i)=>{
                if(dato1 == true){

                arrayMaster.push(
                  <Grid item class="flip-card" style={{margin:20}}>
                  <Grid item class="flip-card-inner">
                    <Grid item class="flip-card-front">
                      <img src={`${dato.ruta}${i+1}.png`} alt="Avatar"  style={{width: 213 , height: 280,  borderRadius:15}}/>
                    </Grid>
                    <Grid class="flip-card-back">
                    <img src={`${dato.ruta}${i+1}_1.png`}  alt="Avatar" style={{width: 213 , height: 280,  borderRadius:15}}/>
                    </Grid>
                  </Grid>
                </Grid>
                  )

                }else{

                  arrayMaster.push(
                    <Grid item class="flip-card" style={{margin:20}}>
                    <Grid item class="flip-card-inner">
                      <Grid item class="flip-card-front">
                        <img src={`/imagenes/cards/1_step/bloqueadas/4.png`} alt="Avatar"  style={{width: 213 , height: 280,  borderRadius:15}}/>
                      </Grid>
                      <Grid class="flip-card-back">
                      <img src={`/imagenes/cards/1_step/bloqueadas/4.png`}  alt="Avatar" style={{width: 213 , height: 280, borderRadius:15}}/>
                      </Grid>
                    </Grid>
                  </Grid>
                    )


                }



                  setNivel4({...nivel4,
                    ['esSubcategoria']: false,
                    ['arrayNivel']: arrayMaster});

                  })

             }

             if(dato.subcategoria.length !== 0){

              for(let i = 0; i<dato.numeroCartas; i++){

                let arrayMaster2 = []
                dato.subcategoria.map((dato)=>(

                    arrayMaster2.push(dato)
                ))
             

                setNivel4({...nivel4,
                  ['esSubcategoria']: true,
                  ['ruta']: dato.ruta,
                  ['arrayNivel']: arrayMaster2
                  });

             
            

             }
            }
             
             setNumerador(numerador+1)}} style={{  boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',height:239, width:185, borderRadius:15}}/>

           </Grid>


            ))}
              
              </>

              :
              
              numerador === 4 ?
              <>
               {   nivel4.esSubcategoria === false  ?
             nivel4.arrayNilvel.map(dato=>(
               dato
             ))

             :
             {
               

             }}
              
              </>

              :

              <>
              <Cards3Segundo arrayImagenes={imagenesDesciption}/>
              
              </>

              
              
:
<h1>hola</h1>
              
              
     

}
    
  </Grid> 
    
            
</>
       
    );
}
 
export default Cards3Primero;