import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AccesoriesSegundo from './AccesoriesSegundo';
import AccesoriesDescription from './AccesoriesDescription';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
  }));

const AccesoriesPrimero = ({datosArray}) => {

    const classes = useStyles();

    const [numerador, setNumerador] = useState(0);

    const cartasPrincipales = [1,2,3,4,5,6,7,8]

    const activador = true;

    let imagenesArticle =[
      {
          frente: 'a.png' ,
          reverso: 'a 1.png'
      },
      {
          frente: 'an.png' ,
          reverso: 'an 1.png'
      },
      {
          frente: 'the.png' ,
          reverso: 'the 1.png'
      }
  ]

  let imagenesPossesion =[
    {
        frente: 'HER.png' ,
        reverso: 'her 1.png'
    },
    {
        frente: 'his.png' ,
        reverso: 'his 1.png'
    },
    {
        frente: 'my.png' ,
        reverso: 'my 1.png'
    },
    {
        frente: 'your.png' ,
        reverso: 'your 1.png'
    }
]

let imagenesIndicator =[
    {
        frente: 'that.png' ,
        reverso: 'that 1.png'
    },
    {
        frente: 'these.png' ,
        reverso: 'these 1.png'
    },
    {
        frente: 'this.png' ,
        reverso: 'this 1.png'
    },
    {
        frente: 'those.png' ,
        reverso: 'those 1.png'
    }
]

let imagenesDescription =[
    {
        frente: 'that.png' ,
        reverso: 'that 1.png'
    }
    
]

let imagenesQuantifier =[
    {
        frente: 'any.png' ,
        reverso: 'any 1.png'
    },
    {
        frente: 'many.png' ,
        reverso: 'many 1.png'
    },
    {
        frente: 'much.png' ,
        reverso: 'much 1.png'
    },
    {
        frente: 'some.png' ,
        reverso: 'some 1.png'
    }
    
]

let imagenesName =[
    {
        frente: 'that.png' ,
        reverso: 'that 1.png'
    }
    
]

let imagenesSuperDescription =[
    {
        frente: 'that.png' ,
        reverso: 'that 1.png'
    }
    
]

let imagenesIntesifier =[
    {
        frente: 'very.png' ,
        reverso: 'very 1.png'
    },
    {
        frente: 'too.png' ,
        reverso: 'too 1.png'
    },
    {
        frente: 'really.png' ,
        reverso: 'really 1.png'
    },
    
]

  



    return (  
      <>
     {numerador !==0  ? <div style={{padding:20}} >
      <Button variant="contained" onClick={()=>{setNumerador(0)}}>Regresar</Button>
      </div> :
       <div style={{padding:20}} >
         
       </div>

    }
      <Grid
      spacing={5}
      container
      direction="row"
      justify="center"
      alignItems="center"
    >
     {
               numerador === 0 ?
                <>

                {
                  datosArray.map((dato, i)=>(

                    dato === false ?

                    <Grid item key={i} >
                    <input type="image" src={`/imagenes/accesories/categories/lock.png`}  style={{height:150, width:249}}/>
      
                    </Grid>
   
                    :

                    <Grid item key={i} >
                 <input type="image" src={`/imagenes/accesories/categories/${i+1}.png`} onClick={()=> setNumerador(i+1)} style={{height:150, width:249}}/>
   
                 </Grid>


                  ))
                }
                
              

               
               
               
               </> :
               
               numerador === 1 ?
              <>
              <AccesoriesSegundo arrayImagenes={imagenesArticle}/>
              
              </>

              :

              numerador === 2 ?
              <>
              <AccesoriesSegundo arrayImagenes={imagenesPossesion}/>
              
              </>

              :

              numerador === 3 ?
              <>
              <AccesoriesSegundo arrayImagenes={imagenesIndicator}/>
              
              </>

              :

              numerador === 4 ?
              <>
                <AccesoriesDescription/>              
              </>

              :

              numerador === 5 ?
              <>
              <AccesoriesSegundo arrayImagenes={imagenesQuantifier}/>
              
              </>

              :

              numerador === 6 ?
              <>
              <AccesoriesSegundo arrayImagenes={imagenesName}/>
              
              </>

              :

              
              numerador === 7 ?
              <>
              <AccesoriesSegundo arrayImagenes={imagenesSuperDescription}/>
              
              </>

              :

              numerador === 8 ?
              <>
              <AccesoriesSegundo arrayImagenes={imagenesIntesifier}/>
              
              </>

              :

              <>
              <AccesoriesSegundo arrayImagenes={imagenesIndicator}/>
              
              </>

              
              

              
              
     }
  </Grid> 
            
            
</>
       
    );
}
 
export default AccesoriesPrimero;