import firebase from 'firebase/app';


const firebaseConfig = {
    apiKey: "AIzaSyAsKQNQg9aUPBwrFzTMZ2wFVSsheYRaiIU",
    authDomain: "gocards-d1470.firebaseapp.com",
    databaseURL: "https://gocards-d1470.firebaseio.com",
    projectId: "gocards-d1470",
    storageBucket: "gocards-d1470.appspot.com",
    messagingSenderId: "153644990221",
    appId: "1:153644990221:web:a8e0466560dc36917a3a44"
}

export const firebaseApp = firebase.initializeApp(firebaseConfig);