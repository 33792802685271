import React, { Fragment } from "react";

/// Components
import Markup from "./jsx";
import InicioLogin from './login/InicioLogin';
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";


import { withResizeDetector } from "react-resize-detector";
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';

const App = ({ width }) => {
   const body = document.querySelector("body");

   width >= 1300
      ? body.setAttribute("data-sidebar-style", "full")
      : width <= 1299 && width >= 767
      ? body.setAttribute("data-sidebar-style", "mini")
      : body.setAttribute("data-sidebar-style", "overlay");

   return (
      <Fragment>
         <Router>
    <Switch>
      <Route exact path='/' component={InicioLogin}/>
      <Route  path='/dashboard' component={Markup }/>
  
      
 
    </Switch>
  </Router>
         
      </Fragment>
   );
};

export default withResizeDetector(App);
