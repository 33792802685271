import React from 'react';
import Grid from '@material-ui/core/Grid';


const Cards4Segundo = ({arrayImagenes}) => {

  

    return ( 
        <>
      
        {
            arrayImagenes.map((dato)=>(
                <Grid item class="flip-card" style={{margin:20, borderRadius:15}}>
  <Grid item class="flip-card-inner">
    <Grid item class="flip-card-front" style={{borderRadius:15}}>
      <img src={`/imagenes/${dato.frente}`} alt="Avatar"  style={{width: 213 , height: 280,  borderRadius:15}}/>
    </Grid>
    <Grid class="flip-card-back" style={{borderRadius:15}}>
    <img src={`/imagenes/${dato.reverso}`}  alt="Avatar" style={{width: 213 , height: 280,  borderRadius:15}}/>
    </Grid>
  </Grid>
</Grid>
  
            ))
        }

</>

     );
}
 
export default Cards4Segundo;